<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>カテゴリ</v-toolbar-title>
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-10">
                <v-simple-table class="plant-tbl">
                        <tr>
                            <th>カテゴリ名</th>
                            <td>
                                <v-text-field
                                    v-model="categoryName"
                                    type="text"
                                    maxlength="10"
                                    solo
                                    dense
                                    outlined
                                    hide-details="auto"
									:error-messages="err.categoryName"
									@blur="blurCategoryName"
                                />
                           </td>
                        </tr>
                        <tr>
                            <th>並び順</th>
                            <td>
                                <v-text-field
                                    v-model="sortNo"
                                    type="Number"
                                    style="width:45px"
                                    maxlength="2"
                                    solo
                                    dense
                                    outlined
                                    hide-details="auto"
									:error-messages="err.sortNo"
                                />
                           </td>
                        </tr>
                    </v-simple-table>
            </v-card>
            <v-card class="my-5 mx-10 text-right" elevation="0">
                <v-btn class="mr-2 btn-save" @click="saveLately"> 保存 </v-btn>
                <v-btn class="btn-close" @click="dialog = false"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.plant-tbl th {
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../../messages";
import { CONSTANT } from "../../constant";
export default {
    components: { },
	props:{
		callback:{
			type:Function
		},
	},
    data: () => ({
        dialog: false,
        saveLoading: false,
        categoryName: '',
        categoryId:'',
        sortNo:0,
        category:{},
        mode:'Add',
        err: {
            categoryName: '',
            sortNo:'',
        }
    }),
    computed:{
    },
    mounted() {
    },
    methods: {
       open(mode='Add', category={}) {
            this.dialog = true;
            this.mode = mode;
            if(mode=='Add'){
			    this.categoryName='';
			    this.categoryId='';
                this.sortNo=0;
            }else{
                this.categoryName = category.category_name;
                this.sortNo = category.sortno;
                this.categoryId = category.category_id;
            }
       },
       async saveLately() {


            if(await this.inputCheck()){
                // in error
                return;
            }
            if(this.mode=='Edit'){
                const res = await this.axios.post('/siryo/update-category',{category_id:this.categoryId, category_name:this.categoryName, sortno:this.sortNo});
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.callback(res.data.resultData.categorys);
                }
            }else{
                const res = await this.axios.post('/siryo/add-category',{category_name:this.categoryName, sortno:this.sortNo});
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.callback(res.data.resultData.categorys);
                }
            }
			this.dialog = false;
       },
        /**
         * 入力チェック
         */
        inputCheck()
        {
            //error clear
            this.clearError();

			if(!this.categoryName){
                this.err.categoryName = [MESSAGE.formatByArr(MESSAGE.E001,'カテゴリ名')];
			}
            
            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
        },
       /**
       * エラーの一括クリア
       */
       clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
       	},
	   	blurCategoryName(){
			if(this.categoryName){
				this.clearError();
			}
	   }
    },
};
</script>

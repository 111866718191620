<template>
    <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card class="pb-5">
            <v-toolbar color="primary" height="40" dark>
                <v-toolbar-title>カテゴリ</v-toolbar-title>
                <v-spacer />
                <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-10">
                <v-simple-table class="plant-tbl">
                        <colgroup>
                            <col style="width:50px;" />
                        </colgroup>
                        <tr>
                            <th>公開日付</th>
                            <td>
                                <input-date-fld  :date.sync="openDate" :error="isError(err.openDate)" />
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.openDate)"
                                >
                                <span v-html="dispError(err.openDate)"></span>
                            </div>
                           </td>
                        </tr>
                        <tr>
                            <th>並び順</th>
                            <td>
                                <v-text-field type="Number" outlined hide-details="auto" :error="isError(err.sortNo)" dense style="width:50px" v-model="sortNo"></v-text-field>
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.sortNo)"
                                >
                                <span v-html="dispError(err.sortNo)"></span>
                                </div>
                           </td>
                        </tr>
                        <tr>
                            <th>表示区分</th>
                            <td>
                                <v-radio-group v-model="dispFlg" row hide-details="auto">
                                    <v-radio v-for="val in hyojikbn"
                                    :key="val.val"
                                    :label="val.label"
                                    :value="val.val"
                                    ></v-radio>
                                </v-radio-group>
                           </td>
                        </tr>
                        <tr>
                            <th>表示</th>
                            <td>
                                <v-row class="ma-1">
                                <v-checkbox class="mr-7" hide-details="auto" true-value="1" false-value="0" v-model="dispMemberFlg"  dense label="会員に表示" ></v-checkbox>
                                <v-checkbox hide-details="auto" true-value="1" false-value="0" v-model="dispGeneralFlg" dense label="一般に表示" ></v-checkbox>
                                </v-row>
                                <div
                                    class="controll-error ml-2"
                                    v-show="isError(err.dispFlg)"
                                >
                                <span v-html="dispError(err.dispFlg)"></span>
                                </div>
                                
                            </td>
                        </tr>
                        <tr>
                            <th>種別</th>
                            <td>
                                <v-radio-group v-model="fileType" row hide-details="auto">
                                    <v-radio v-for="val in radio"
                                    :key="val.val"
                                    :label="val.label"
                                    :value="val.val"
                                    ></v-radio>
                                </v-radio-group>
                           </td>
                        </tr>
                        <tr>
                            <th>タイトル</th>
                            <td>
                                <v-text-field dense outlined hide-details="auto" :error-messages="err.siryoFileTitle" v-model="siryoFileTitle"></v-text-field>
                            </td>
                        </tr>
                        <tr v-if="fileType==1">
                            <th>ファイル</th>
                            <td>
                                    <input style="display:none" type="file" id="file" ref="file" @change="fileChange" />
                                    <v-btn class="btn-edit" @click="clickFileUpload">アップロード</v-btn>
                                    {{siryoOrgFileName}}
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.siryoOrgFileName)"
                                    >
                                    <span v-html="dispError(err.siryoOrgFileName)"></span>
                                    </div>
                           </td>
                        </tr>
                        <tr v-if="fileType==1">
                            <th>保存時のファイル名</th>
                            <td>
                                <v-text-field dense outlined hide-details="auto"  v-model="siryoDlName" :error-messages="err.siryoDlName"></v-text-field>
                            </td>
                        </tr>
                        <tr v-if="fileType==2">
                            <th>リンク</th>
                            <td>
                                <v-text-field outlined hide-details="auto" v-model="siryoUrl" :error-messages="err.siryoUrl" dense></v-text-field>
                           </td>
                        </tr>
                    </v-simple-table>
            </v-card>
            <v-card class="my-5 mx-10 text-right" elevation="0">
                <v-btn class="mr-2 btn-save"
                :loading="saveLoading"
                :disabled="saveLoading"
                 @click="saveSiryo"> 保存 </v-btn>
                <v-btn class="btn-close" @click="dialog = false"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.plant-tbl th {
    width: 200px;
}
</style>
<script>
import { MESSAGE } from "../../messages";
import { CONSTANT } from "../../constant";
import InputDateFld from "../InputDateFld";

export default {
    components: { InputDateFld },
	props:{
		callback:{
			type:Function
		}
	},
    data: () => ({
        dialog: false,
        loader: null,
        saveLoading: false,
        mode:'add',
        //選択されているカテゴリ
        category:{},
        uploadFileName:'',
        //入力項目
        openDate:'',
        siryoFileTitle:'',
        siryoOrgFileName:'',
        siryoMimeType:'',
        siryoUrl:'',
        siryoDlName:'',
        sortNo:0,
        fileData:'',
        siryoId:'',
        dispMemberFlg:'0',
        dispGeneralFlg:'0',
        dispFlg:1,
        hyojikbn:[
            {val:0, label:'非表示'},
            {val:1, label:'表示'},
        ],
        err: {
            openDate:'',
            sortNo:0,
            siryoFileTitle:'',
            siryoOrgFileName:'',
            siryoUrl:'',
            siryoDlName:'',
        },
        fileType:1,
        radio:[
            {val:1, label:'ファイル'},
            {val:2, label:'リンク'},
        ]
    }),
    computed:{
        isFileSet(){
            if(this.siryoOrgFileName.length>0){
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.openDate = this.dateFormat(new Date())
    },
    methods: {
        open(category, siryo=null) {
            this.clearError();
            this.saveLoading = false;
            this.category = category;
            this.dialog = true;
            this.siryoDlName='';
            this.siryoOrgFileName='';
            this.siryoFileTitle='';
            this.mode='add'
            this.siryoId = '';
            this.dispMemberFlg = 0; 
            this.dispGeneralFlg =0; 
            this.dispFlg = 1;
            if(siryo){
                console.log(siryo);
                this.mode='update';
                this.siryoOrgFileName = siryo.siryo_org_file_name;
                this.sortNo = siryo.sortno;
                this.openDate = siryo.open_date;
                this.fileType = siryo.siryo_kbn;
                this.siryoDlName = siryo.siryo_dl_name;
                this.siryoFileTitle = siryo.siryo_title;
                this.siryoMimeType = siryo.siryo_file_mime_type;
                this.siryoId = siryo.siryo_id;
                this.siryoUrl = siryo.siryo_url;
                this.dispMemberFlg = String(siryo.disp_member_flg);
                this.dispGeneralFlg = String(siryo.disp_general_flg);
                this.dispFlg = siryo.disp_flg;
                this.getSiryo(siryo.siryo_id);
            }
        },
        async getSiryo(siryoId){

            this.saveLoading = true;
			const res = await this.axios.post('/siryo/get-siryo-file',{siryo_id:siryoId});

            this.saveLoading = false;
            if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                this.fileData = res.data.resultData.siryo_base64_file;
                this.siryo_file_mime_type = res.data.resultData.siryo_file_mime_type;
            }
        },
        async saveSiryo() {

        
            //入力チェック
            if(await this.inputCheck()){
                return;
            }
            const fileInfo = {
                siryo_id: this.siryoId,
                open_date:this.openDate,
                category_id:this.category.category_id,
                sort_no:this.sortNo,
                file_type:this.fileType,
                siryo_file_title :this.siryoFileTitle,
                siryo_dl_name:this.siryoDlName,
                siryo_url:this.siryoUrl,
                siryo_org_file_name: this.siryoOrgFileName,
                siryo_file_data:this.fileData,
                siryo_file_mime_type: this.siryoMimeType,
                disp_member_flg:this.dispMemberFlg,
                disp_general_flg:this.dispGeneralFlg,
                disp_flg:this.dispFlg,
            };
            this.loader='loading'
            this.saveLoading = true;

            if(this.mode=='add'){
                const res = await this.axios.post("/siryo/add-siryo", fileInfo);
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,'資料'));
                    this.dialog = false;
                    this.callback(res.data.resultData.siryos);
                    this.loader = null;
                    this.saveLoading = false;
                    return;
                }
            }else{
                const res = await this.axios.post("/siryo/update-siryo", fileInfo);
                if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,'資料'));
                    this.dialog = false;
                    this.callback(res.data.resultData.siryos);
                    this.loader = null;
                    this.saveLoading = false;
                    return;
                }
            }

        },
        /**
         * 入力チェック
         */
        inputCheck()
        {
            //error clear
            this.clearError();

            // 公開日付
            if(!this.openDate){
                this.err.openDate = [MESSAGE.formatByArr(MESSAGE.E002,'公開日付')];
            }
            // 並び順
            if(this.sortNo === ''){
                this.err.sortNo = [MESSAGE.formatByArr(MESSAGE.E001,'並び順')];
            }
            if(this.dispGeneralFlg=='0' && this.dispMemberFlg=='0'){
                this.err.dispFlg= [MESSAGE.formatByArr(MESSAGE.E002,'表示する画面')];

            }
            //タイトル
            if(this.siryoFileTitle === ''){
                this.err.siryoFileTitle = [MESSAGE.formatByArr(MESSAGE.E001,'タイトル')];
            }
            if(this.fileType=='1'){
                //保存時のファイル名
                if(this.siryoDlName === ''){
                    this.err.siryoDlName = [MESSAGE.formatByArr(MESSAGE.E001,'保存時のファイル名')];
                }
                //アップロードファイル
                if(this.siryoOrgFileName===''){
                    this.err.siryoOrgFileName= [MESSAGE.formatByArr(MESSAGE.E002,'アップロードファイル')];
                }
            }else{
                //リンク
                if(this.siryoUrl === ''){
                    this.err.siryoUrl = [MESSAGE.formatByArr(MESSAGE.E001,'リンクURL')];
                }

            }

            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }

            return false;
        },
        clickFileUpload(){
            this.$refs.file.click();
        },
        fileChange(){
            this.siryoOrgFileName = this.$refs.file.files[0].name;
            this.siryoMimeType = this.$refs.file.files[0].type;
            const reader = new FileReader();
            const file = this.$refs.file.files[0];
            if(file){
                reader.readAsDataURL(file);
            }
            reader.onload=()=>{
                this.fileData = reader.result;//.replace(/data:.*\/.*;base64,/, '');
            }

        },
       /**
       * エラーの一括クリア
       */
       clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
       	},
    },
};
</script>
